@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
*, *::after, *::before {
  box-sizing: border-box;
}

:root {
  --color1: #093660;
  --color2: #e5e5e5;
  --color3: #23d3d3;
  --color4: #252e43;
  --color5: #202227;
  --color6: #22cfd0;
  --color7: #2ea0cf;
  --color8: #26b6ce;
  --color9: #b5bec9;
  --color10: #95a4b7;
  --color11: #3a5e80;
  --color12: #537290;
  --color13: #ffffff;
  --color14: #21b7c1;
  --color15: #ff3f3f;
  --color16: #ececf0;
  --color17: #808da9;
  --color18: #50a8d4;
  --color19: #d3d7dd;
  --color20: #486791;
  --color21: #6e7898;
  --color22: #41bfd4;
  --color23: #f1f5f8;
  --color24: #adb2b9;
  --color25: #00b1fd;
  --colorA: rgba(9, 54, 96, 0.7);
  --colorB: rgba(181, 190, 201, 0.7);
  --colorC: rgba(50, 65, 92, 0.8);
  --colorD: rgba(37, 46, 67, 0.7);
  --colorE: rgba(0, 0, 0, 0.25);
  --colorF: rgba(181, 190, 201, 0.48);
  --colorG: rgba(101, 41, 255, 0.15);
  --colorH: rgba(145, 223, 230, 0.53);
  --colorI: rgba(0, 0, 0, 0.15);
  --colorJ: rgba(37, 46, 67, 0.36);
}

body {
  font-family: Lato;
  font-style: normal;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.content {
  min-height: calc(100vh - 50vh);
}

